$basefont: "Poppins", sans-serif;
$black: black;
$white: white;
$body: #17191A;

$baseclr: #E2A92A;
$baseclr2: #E2AA29;

$black2E: #2E3644;
$green: #4ACF67;

$headerZ: 988;
$redColor: #D42503;
$inputBg: #202020;
$darkGray: #202020;
$red: #F23645;
$baseColor1: #E2AA29;

%transition {
    transition: 0.3s all ease-in-out;
}