@import "../../../../assets/theme/_var.scss";

.feature_sec {
    padding: 8.8rem 0 7.2rem;

    @media (max-width: 767px) {
        padding: 6rem 0;
    }

    .container {
        @media (min-width: 1400px) {
            max-width: 178.6rem;
        }
    }

    .feature_sec_in {
        .row {
            margin: -0.75rem;

            >div {
                padding: 0.75rem;
            }

            @media (min-width: 1780px) {
                margin: -1.9rem;

                >div {
                    padding: 1.9rem;
                }
            }
        }

        .feature_inner {
            background: $black2E;
            border-radius: 2rem;
            padding: 4rem 4.2rem;
            height: 56.1rem;
            overflow: hidden;

            @media (max-width: 1679px) {
                padding: 3rem 3rem 0;
                height: 48rem;
            }

            @media (max-width: 1479px) {
                text-align: center;
                padding: 2.4rem;
                height: 100%;
            }

            h3 {
                font-size: 3rem;
                font-weight: 600;
                line-height: 4.5rem;

                @media (max-width: 1679px) {
                    font-size: 2.4rem;
                    line-height: 3.4rem;
                }

                @media (max-width:1479px) {
                    text-align: left;
                    font-size: 2rem;
                    line-height: 3rem;
                }
            }

            p {
                font-size: 1.8rem;
                line-height: 3rem;
                font-weight: 300;
                max-width: 47.6rem;
                width: 100%;

                @media (max-width:1679px) {
                    font-size: 1.6rem;
                    line-height: normal;
                }

                @media (max-width:1479px) {
                    text-align: left;
                    font-size: 1.4rem;
                    margin: 1rem 0 2rem;
                }
            }

            img {
                width: 100%;

                @media (max-width: 1200px) {
                    max-width: 24rem;
                    margin: 2rem auto 0;
                    display: block;
                }
            }

            .crypto_img {
                width: 51.2rem;
                max-width: none;
                transform: translate(5.9rem, -3.5rem);

                @media (max-width: 1679px) {
                    width: 40rem;
                    transform: translate(7.9rem, -1.5rem);
                }

                @media (max-width: 1479px) {
                    max-width: 100%;
                    transform: none;
                    width: 24rem;
                }
            }

            .wallet_img {
                width: 41.3rem;
                transform: translate(10rem, 2rem);

                @media (max-width: 1679px) {
                    width: 34rem;
                    transform: translate(10rem, 2.6rem);
                }

                @media (max-width: 1479px) {
                    width: 24rem;
                    transform: none;
                }
            }

            .crypto_network_img {
                width: 41.4rem;
                transform: translate(10rem, 1.5rem);

                @media (max-width: 1679px) {
                    width: 36rem;
                }

                @media (max-width: 1479px) {
                    width: 24rem;
                    transform: none;
                }
            }

            @media (max-width: 1199px) {

                .wallet_img,
                .crypto_network_img,
                .crypto_img {
                    width: 18rem;
                    margin-left: auto !important;
                    margin: unset;
                }
            }
        }
    }
}