@import "../../../assets/theme/_var.scss";

.footer {
    margin-top: auto;
    background-color: $black2E;

    .container {
        @media (min-width: 1400px) {
            max-width: 162.1rem;
        }
    }

    .footer_in {
        padding: 7.1rem 0 3.4rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 991px) {
            padding: 3rem 0;
            flex-wrap: wrap;
        }

        .footer_content {
            @media (max-width: 991px) {
                width: 100%;
            }

            .logo {
                cursor: pointer;
            }
        }

        h3 {
            font-size: 1.8rem;
            margin-bottom: 2.5rem;
            font-weight: 700;
            line-height: 2.7rem;

            @media (max-width: 1679px) {
                line-height: 1.5;
                font-size: 1.6rem;
            }

            @media (max-width: 991px) {
                margin-bottom: 1.4rem;
            }
        }

        .footer_nav {
            display: flex;
            margin-left: 10rem;

            @media (max-width: 1479px) {
                margin-left: 6.5rem;
            }

            @media (max-width: 1199px) {
                margin-left: 6rem;
            }

            @media (max-width: 991px) {
                width: 100%;
                order: 1;
                margin: 0;
            }

            &_list {
                &:not(:last-child) {
                    margin-right: 12.3rem;

                    @media (max-width :1199px) {
                        margin-right: 5rem;
                    }
                }

                li {
                    &:not(:last-child) {
                        margin-bottom: 1.5rem;
                    }

                    a {
                        font-size: 1.4rem;
                        color: $white;
                        font-weight: 400;
                        line-height: 2.1rem;
                        transition: 200ms ease-in-out;
                        cursor: pointer;

                        &:hover {
                            color: $baseclr;
                        }
                    }
                }
            }
        }

        .stay_touch {
            @media (max-width: 991px) {
                width: 100%;
                margin: 2rem 0;
            }

            .footer_txt {
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 2.1rem;

                @media (max-width: 991px) {
                    br {
                        display: none;
                    }
                }
            }

            form {
                margin: 4rem 0 2.6rem;

                @media (max-width: 991px) {
                    margin: 2rem 0;
                }

                .input {
                    position: relative;

                    input {
                        max-width: 100%;
                        width: 45.7rem;
                        font-size: 1.2rem;
                        font-weight: 400;
                        line-height: 1.8rem;
                        padding: 0 15rem 0 2.3rem;
                        border-radius: 5rem;
                        border: 2px solid transparent;
                        outline: none;
                        height: 5rem;
                        background-color: rgba($white, 0.1);
                        color: $white;

                        @media (max-width: 1679px) {
                            height: 4.8rem;
                        }

                        @media (max-width: 1479px) {
                            width: 36rem;
                            height: 4.4rem;
                        }

                        @media (max-width: 991px) {
                            width: 100%;
                        }
                    }

                    button {
                        &[type="submit"] {
                            position: absolute;
                            width: 14.6rem;
                            top: 0;
                            right: 0;
                        }
                    }
                }
            }

            ul {
                display: flex;
                align-items: center;

                li {
                    &:not(:last-child) {
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }

    .footer_end {
        border-top: 1px solid rgba($white, 0.12);
        padding: 2.6rem 0 2.2rem;

        .footer_end_in {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 767px) {
                flex-wrap: wrap;
            }

            p {
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 2.1rem;

                @media (max-width: 767px) {
                    width: 100%;
                    text-align: center;
                }
            }

            ul {
                display: flex;
                align-items: center;

                @media (max-width: 767px) {
                    width: 100%;
                    justify-content: center;
                    margin-top: 1rem;
                }

                li {
                    margin-left: 1rem;

                    a {
                        font-size: 1.4rem;
                        color: $white;
                        font-weight: 400;
                        line-height: 2.1rem;
                        transition: 200ms ease-in-out;

                        &:hover {
                            color: $baseclr;
                        }
                    }
                }
            }
        }
    }
}