@import "../../../../assets/theme/_var.scss";

.market_prices {
    padding: 17rem 0 8.8rem;
    background-image: url("../../../../assets/images/market-bg.png");
    background-size: 100% 80%;
    background-position: top;
    background-repeat: no-repeat;

    @media (max-width: 1479px) {
        padding: 8rem 0;
        background-size: contain;
        background-position: center;
    }

    @media (max-width: 767px) {
        padding: 6rem 0;
    }

    .container {
        @media (min-width: 1440px) {
            max-width: 130.4rem;
        }
    }

    .table {
        vertical-align: middle;
        border-collapse: separate;
        border-spacing: 0 1rem;
        margin: -1rem 0 0;
        white-space: nowrap;

        thead {
            tr {
                th {
                    padding: 2.4rem 1.6rem 2.1rem;
                    font-size: 1.8rem;
                    font-weight: 700;
                    line-height: 2.7rem;
                    color: $white;
                    background-color: $baseclr2;
                    border: none;

                    @media (max-width: 1679px) {
                        padding: 1.4rem 1.6rem;
                        font-size: 1.6rem;
                    }

                    &:nth-child(2) {
                        min-width: 18rem;

                        @media (max-width: 991px) {
                            min-width: 14rem;
                        }
                    }

                    &:first-child {
                        border-top-left-radius: 2rem;
                        border-bottom-left-radius: 2rem;
                        width: 10rem;
                        padding-left: 4rem;

                        @media (max-width: 575px) {
                            padding-left: 2rem;
                        }
                    }

                    &:last-child {
                        border-top-right-radius: 2rem;
                        width: 13.7rem;
                        border-bottom-right-radius: 2rem;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    background-color: $black2E;
                    font-size: 1.6rem;
                    font-weight: 500;
                    padding: 1.4rem 1.6rem 1.2rem;
                    line-height: 2.4rem;
                    color: $white;
                    border: none;

                    @media (max-width: 1679px) {
                        padding: 0.6rem 1.6rem;
                        font-size: 1.4rem;
                    }

                    &.change_txt {
                        svg {
                            margin-right: 0.4rem;
                        }
                    }

                    .coin_detail {
                        display: flex;
                        gap: 1rem;
                        align-items: center;

                        img {
                            width: 4.4rem;
                            height: 4.4rem;
                            border-radius: 50%;
                            flex-shrink: 0;
                            margin-right: 1rem;

                            @media (max-width: 1679px) {
                                width: 3.2rem;
                                height: 3.2rem;
                            }
                        }

                        h4 {
                            font-weight: 700;
                            font-size: inherit;
                        }

                        p {
                            font-size: 1.4rem;
                            font-weight: 400;
                            line-height: 2.1rem;
                            color: rgba($white, 0.4);

                            @media (max-width: 1679px) {
                                font-size: 1.2rem;
                            }
                        }
                    }

                    .buy_btn {
                        font-size: inherit;
                        font-weight: 700;
                        line-height: 2.4rem;
                        padding: 0;
                        border: none;
                        outline: none;
                        background-color: transparent;
                        color: $green;
                    }

                    &:first-child {
                        border-top-left-radius: 2rem;
                        padding-left: 4rem;
                        border-bottom-left-radius: 2rem;

                        @media (max-width: 575px) {
                            padding-left: 2rem;
                        }
                    }

                    &:last-child {
                        border-top-right-radius: 2rem;
                        border-bottom-right-radius: 2rem;
                    }
                }
            }
        }
    }
}