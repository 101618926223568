@import "../../../../assets/theme/_var.scss";


.trade_sec {
    margin: 7.2rem 0 0;
    background-image: url("../../../../assets/images/trade_bg.png");
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 767px) {
        margin: 6rem 0 0;
    }

    .trade_sec_in {
        padding: 12.8rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        @media (max-width: 1679px) {
            padding: 8rem 0;
        }

        @media (max-width: 1479px) {
            padding: 6rem 0;
        }

        .title_txt {
            max-width: 46rem;
            width: 100%;

            h2 {
                margin-bottom: 1.2rem;
            }

            @media (max-width: 1679px) {
                max-width: 40rem;

                h2 {
                    margin-bottom: 0.4rem;
                }
            }

            @media (max-width: 1479px) {
                max-width: 32rem;
            }
        }

        p {
            font-size: 1.8rem;
            font-weight: 300;
            margin-bottom: 4.1rem;
            max-width: 53.1rem;
            width: 100%;

            @media (max-width:1679px) {
                font-size: 1.6rem;
                max-width: 48rem;
                margin-bottom: 2.4rem;
            }

            @media (max-width: 1479px) {
                font-size: 1.4rem;
                max-width: 40rem;
            }
        }

        .trade_btn {
            width: 21.2rem;
            height: 6rem;
            padding: 0.5rem;
            justify-content: flex-end;
            font-weight: 600;

            span {
                flex-shrink: 0;
                width: 5rem;
                border-radius: 50%;
                height: 5rem;
                background-color: rgba($white, 0.2);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                transition: 200ms ease-in-out;
                margin-left: 2rem;

                svg {
                    transition: 200ms ease-in-out;
                }
            }

            @media (max-width: 1679px) {
                height: 4.8rem;
                width: 18rem;

                span {
                    height: 3.8rem;
                    width: 3.8rem;
                    padding: 1rem;
                }
            }

            @media (max-width: 1479px) {
                height: 4.4rem;

                span {
                    width: 3.4rem;
                    height: 3.4rem;
                }
            }

            &:hover {
                span {
                    background-color: $baseclr;
                }
            }
        }


    }

}