@import "../../../assets/theme/_var.scss";

.error_message {
  color: $redColor;
  margin-top: .5rem;
  font-size: 1.2rem;
  line-height: 1.3;
  font-weight: 500;
  text-align: start;
  
  // position: absolute;

  span {
    margin-right: 0.4rem;
    position: relative;
    top: -0.18rem;
  }

  @media (max-width: 991px) {
    font-size: 1.4rem;
  }

  @media (max-width: 575px) {
    font-size: 1.2rem;
  }
}

.checkbox_error {
  color: $redColor;
  margin-top: 5.2rem;
  font-size: 1.2rem;
  line-height: 1.3;
  font-weight: 500;
  text-align: start;
  position: absolute;

  span {
    margin-right: 0.4rem;
    position: relative;
    top: -0.18rem;
  }

  @media (max-width: 991px) {
    font-size: 1.4rem;
  }

  @media (max-width: 575px) {
    font-size: 1.2rem;
  }
}