@import "../../../assets/theme/_var.scss";

.common-modal {
  &.modal {
    background: rgba($black, 0.5);
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      backdrop-filter: blur(1rem);
      -webkit-backdrop-filter: blur(1rem);
      height: 100%;
      width: 100%;
    }
  }
  .modal {
    &-dialog {
      margin: 0 auto;
      padding: 1.5rem;
      max-width: 54.2rem;
    }
    &-content {
      border-radius: 2rem;
      background: rgba(28, 28, 28, 0.8);
      border: 1px solid rgba($white, 0.1);
      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        backdrop-filter: blur(1rem);
        -webkit-backdrop-filter: blur(1rem);
        height: 100%;
        width: 100%;
      }
    }
    &-header {
      padding: 2rem 5rem 2rem 3rem;
      color: $white;
      border-color: rgba($white, 0.1);
      @media (max-width:1679px) {
        padding: 2rem 5rem 2rem 2rem;
      }
      .btn-close {
        position: relative;
        top: 0rem;
        opacity: 1;
        // background: transparent url(../../../../assets/images/images/cross-icon.svg) center/2.2rem auto no-repeat;
        background: transparent url(../../../assets/images/cross-icon.svg) center/2.2rem auto no-repeat;

        margin-right: -3rem;
        @extend %transition;
        &:focus {
          box-shadow: none;
        }
        &:hover {
          transform: scale(1.25);
        }
      }
      &.cross-only {
        border: 0;
      }
    }
    &-title {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1;
      @media (max-width:1679px) {
        font-size: 1.6rem;
      }
    }
    &-body {
      padding: 3rem 3rem 4.5rem;
      @media (max-width:1679px) {
        padding: 2.5rem 1.5rem;
      }
    }
  }
}