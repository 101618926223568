    .contact_us_modal {
        .contact_us_data{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .contact_us_data_inter{
                margin: 4.5rem  0 2.3rem;
                padding: 0 4rem ;
                width: 100%;
                ul{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    li{
                        display: flex;
                        // align-items: center;
                        .text-start{
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }