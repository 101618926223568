@import "../../../assets/theme/_var.scss";

.title_txt {
    h2 {
        font-size: 4rem;
        font-weight: 700;
        line-height: 6rem;
        text-align: center;
        margin-bottom: 4.4rem;

        @media (max-width: 1679px) {
            font-size: 3rem;
            line-height: 4.2rem;
            margin-bottom: 3.4rem;
        }

        @media (max-width: 1479px) {
            font-size: 2.4rem;
            line-height: 3.4rem;
            margin-bottom: 2.4rem;
        }

        @media (max-width: 767px) {
            font-size: 2rem;
            line-height: 3rem;
            margin-bottom: 1.8rem;
        }
    }
}