@import "../../../assets/theme/_var.scss";

.common_input {
  position: relative;
  margin-bottom: 2rem;
  caret-color: white;

  .form-label {
    font-weight: 400;
    font-size: 1.4rem;
    color: rgba($white, 0.8);
    margin-bottom: 1rem;
    line-height: 1;
  }

  &_inner {
    position: relative;

    .leftIcon,
    .rightIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .leftIcon {
      position: absolute;
      left: 2rem;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;

      &.btn {
        cursor: pointer;
      }
    }

    .rightIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 2.6rem;
      background-color: transparent;

      &.btn {
        cursor: pointer;
      }

      @media (max-width: 991px) {
        right: 1.2rem;

        svg {
          max-width: 1.5rem;
        }
      }

      &.cursor-pointer {
        cursor: pointer;
        height: calc(100% - 0.5rem);
        width: 5.5rem;
        right: 0.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }

    input,
    textarea {
      height: 5.6rem;
      width: 100%;
      border: 0;
      border-radius: 1.4rem;
      padding: 1rem 1.8rem;
      font-size: 1.6rem;
      font-weight: 400;
      color: $white;
      background-color: $inputBg;

      @media (max-width: 991px) {
        font-size: 1.2rem;
        height: 4.8rem;
      }

      &:focus,
      &:hover {
        background-color: $inputBg;
        color: $white;
        box-shadow: none;
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: $white;
        overflow: clip;
        transition: background-color 5000s ease-in-out 0s;
        backdrop-filter: blur(5.4rem);
        -webkit-backdrop-filter: blur(5.4rem);
        --tw-shadow: 0 0 0px 1000px $darkGray inset !important;
        -webkit-box-shadow: 0 0 0px 1000px $darkGray inset !important;
      }

      &::placeholder {
        color: $white;
        opacity: .3;
      }

      &:-moz-placeholder {
        color: $white;
        opacity: .3;
      }

      &::-webkit-input-placeholder {
        color: $white;
        opacity: .3;
      }

      &:disabled {
        background-color: $inputBg;
        cursor: not-allowed;
        opacity: .5;
      }
    }

    &.rightIconInput {
      input {
        padding-right: 4.6rem;
      }

      // span {
      //   right: 0 !important;
      // }
    }

    &.leftIconInput {
      input {
        padding-left: 4.75rem;
      }
    }
  }

  &.search {
    min-width: 62.5rem;
    margin-bottom: 0;

    @media (max-width: 767px) {
      margin-top: 1rem;
    }

    @media (max-width: 1199px) {
      min-width: 100%;
    }

    input {
      background: $white;

      @media (max-width: 1199px) {
        height: 4rem;
        font-size: 1.3rem;
      }
    }

    button {
      width: 5.6rem;
      height: 3.25rem;
      // background: $gray;
      border-radius: 0.8rem;
      border: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .common_input_inner {
      .rightIcon {
        right: 1rem;
      }
    }

    .error_message {
      position: absolute;
      padding: 1rem;
      width: 100%;
      text-align: center;
      background: $white;
      border-radius: 0.8rem;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }

  &.light-input {
    margin-bottom: 2rem;

    .form-label {
      margin-bottom: 1rem;
    }

    .common_input_inner {
      input {
        background: $white;
        // border: 0.1rem solid $border-clr;
      }
    }
  }

  // error message
  &--error {

    input,
    textarea {
      border-color: $redColor;
      color: $redColor;

      &::placeholder {
        color: $white;
      }

      &::-webkit-input-placeholder {
        color: $white;
      }

      &:-moz-placeholder {
        color: $white;
      }

      &:hover,
      &:focus {
        border-color: $redColor;
      }
    }
  }

  @media (max-width:1679px) {
    margin-bottom: 1rem;
  }
}

.InputHeading {
  .labelheading {
    color: $white;
    font-weight: 600;
    line-height: normal;

    sup {
      color: $red;
    }
  }

  .subtext {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.5rem;
    font-weight: 500;
    // margin-top: 0.5rem;
    margin-top: 1.2rem;
    margin-bottom: 2.4rem;
    line-height: 1;

    @media(max-width: 575px) {
      font-size: 1.2rem;
    }
  }
}

.common_radio {
  position: relative;

  &_inner {
    margin-right: 3rem;

    .form {
      &-check {
        &-input {
          @media(max-width: 991px) {
            margin-left: -2.1em !important;
            margin-top: 0.9rem;
          }

          @media(max-width: 575px) {
            margin-top: 0.9rem;
          }
        }
      }
    }

    label {
      position: relative;
      cursor: pointer;

      @media(max-width: 575px) {
        font-size: 1.4rem;
      }

      &:before {
        content: "";
        height: 2.4rem;
        width: 2.4rem;
        border: 1px solid $white;
        position: absolute;
        left: -2.8rem;
        top: 0rem;
        border-radius: 50%;

        @media(max-width: 991px) {
          height: 1.8rem;
          width: 1.8rem;
          top: 0.6rem;
        }

        @media(max-width: 575px) {
          top: 0.3rem;
        }
      }
    }

    .form-check {
      &-input {
        border: 0;
        background-image: none;
        background-color: transparent;
        cursor: pointer;

        &:checked {
          background-color: $baseColor1;
        }
      }
    }
  }

  .error_message {
    position: absolute;
    top: 2rem;
  }
}