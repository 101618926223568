@import "../../../assets/theme/_var.scss";

.custom_btn {
    padding: 0 3.2rem;
    border-radius: 5rem;
    font-size: 1.8rem;
    font-weight: 500;
    height: 5rem;
    background-color: $baseclr;
    transition: 200ms ease-in-out;
    color: $white;
    outline: none;
    border: 2px solid $baseclr;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 1679px) {
        font-size: 1.6rem;
        height: 4.8rem;
    }

    @media (max-width: 1479px) {
        font-size: 1.4rem;
        height: 4.4rem;
    }

    &:hover,
    &.active {
        color: $baseclr;
        background-color: transparent;
    }

    &.secondary_btn {
        background-color: transparent;

        &:hover,
        &.active {
            background-color: $baseclr;
            color: $white;
        }
    }
}