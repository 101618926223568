@import "../../../../assets/theme/_var.scss";

.banner_sec {
    padding: 20rem 0;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow-x: clip;
    z-index: 1;

    @media (max-width: 1679px) {
        padding: 20rem 0;
    }

    @media (max-width: 1479px) {
        padding: 14rem 0;
    }

    @media (max-width: 991px) {
        padding: 10rem 0 6rem;
    }

    @media (max-width: 767px) {
        min-height: auto;
        padding: 14rem 0 6rem;
    }

    .banner_right_blur {
        position: absolute;
        top: 1.4rem;
        right: -8rem;
        pointer-events: none;
        z-index: -1;
        width: 43.7rem;
        height: 96.9rem;
    }

    .banner_left_blur {
        width: 43.7rem;
        height: 87.8rem;
        pointer-events: none;
        z-index: -1;
        position: absolute;
        top: 33.4rem;
        left: -7.7rem;
    }

    .banner_in {
        text-align: center;
        position: relative;
        width: fit-content;
        margin-inline: auto;
        z-index: 1;

        .blur_circle_big {
            position: absolute;
            top: -35%;
            z-index: -2;
            right: 21%;
            pointer-events: none;
            flex-shrink: 0;
        }

        .blur_circle_small {
            position: absolute;
            top: 25%;
            z-index: -2;
            left: 52%;
            pointer-events: none;
            flex-shrink: 0;
        }

        .blur_circle_big {
            width: 58.3rem;
            transform: translate(calc(-58% - 6.6rem), calc(-30% - 15.6rem));
            height: 58.2rem;

            @media (max-width: 1679px) {
                width: 44rem;
                height: 44rem;
            }

            @media (max-width: 1479px) {
                width: 30rem;
                height: 30rem;
            }

            @media (max-width: 991px) {
                width: 18rem;
                height: 18rem;
                right: 36%;
                transform: translate(calc(-58% - 4rem), calc(-30% - 8rem));
            }
        }

        .blur_circle_small {
            width: 36.6rem;
            transform: translate(calc(-11% + 6.5rem), calc(-80% + 20.9rem));
            height: 36.5rem;

            @media (max-width: 1679px) {
                width: 30rem;
                height: 30rem;
            }

            @media (max-width: 1479px) {
                width: 20rem;
                height: 20rem;
                transform: translate(calc(-11% + 0rem), calc(-80% + 10.9rem));
            }

            @media (max-width: 991px) {
                width: 14rem;
                height: 14rem;
                transform: translate(calc(-11% - 0rem), calc(-80% + 8rem));
            }
        }

        .coin_one,
        .coin_two,
        .coin_three {
            position: absolute;
            z-index: -1;
        }

        .coin_one {
            bottom: -12.3rem;
            left: -12.8rem;

            @media (max-width: 1679px) {
                width: 18rem;
                bottom: -6rem;
                left: -4rem;
            }

            @media (max-width: 1479px) {
                width: 14rem;
                bottom: -3rem;
                left: -2rem;
            }

            @media (max-width: 991px) {
                width: 10rem;
                bottom: -2rem;
                left: 0rem;
            }

            @media (max-width: 459px) {
                bottom: -10rem;
            }
        }

        .coin_two {
            bottom: -23.2rem;
            left: -2.6rem;
            z-index: -2;

            @media (max-width: 1679px) {
                width: 16rem;
                bottom: -10rem;
                left: 1rem;
            }

            @media (max-width: 1479px) {
                width: 12rem;
                bottom: -7rem;
                left: 3rem;
            }

            @media (max-width: 991px) {
                width: 8rem;
                bottom: -4rem;
                left: 4rem;
            }

            @media (max-width: 459px) {
                bottom: -12rem;
            }
        }

        .coin_three {
            top: -13.1rem;
            right: -12.3rem;

            @media (max-width: 1679px) {
                width: 16rem;
                top: -6rem;
                right: -4rem;
            }

            @media (max-width :1479px) {
                width: 14rem;
                top: -6rem;
                right: 1rem;
            }

            @media (max-width: 991px) {
                width: 8rem;
                top: -2rem;
                right: 3rem;
            }

            @media (max-width: 459px) {
                top: -8rem;
            }
        }

        h1 {
            font-size: 6.6rem;
            font-weight: 600;
            line-height: 8.6rem;

            @media (max-width: 1679px) {
                font-size: 5rem;
            }

            @media (max-width: 1479px) {
                font-size: 3.6rem;
                line-height: 5.6rem;

                svg {
                    width: 6rem;
                    height: auto;
                }
            }

            @media (max-width: 1199px) {
                font-size: 3rem;
                line-height: 4rem;

                svg {
                    width: 4rem;
                }
            }

            @media (max-width: 767px) {
                font-size: 2.4rem;

                svg {
                    width: 3rem;
                }
            }

            span {
                color: $baseclr2;
            }
        }

        .desc_txt {
            margin: 2.2rem auto 4.7rem;
            font-size: 2rem;
            font-weight: 300;
            line-height: 3rem;
            color: rgba($white, 0.8);
            max-width: 65.4rem;

            @media (max-width: 1679px) {
                font-size: 1.8rem;
                max-width: 60rem;
            }

            @media (max-width: 1479px) {
                font-size: 1.6rem;
                max-width: 53rem;
                margin: 1.8rem auto 2rem;
            }

            @media (max-width: 991px) {
                font-size: 1.4rem;
                max-width: 46rem;
                line-height: 2.4rem;
            }
        }

        .start_btn {
            width: 21.2rem;
            height: 6rem;
            padding: 0.5rem;
            justify-content: flex-end;
            font-weight: 600;

            span {
                flex-shrink: 0;
                width: 5rem;
                border-radius: 50%;
                height: 5rem;
                background-color: rgba($white, 0.2);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                transition: 200ms ease-in-out;
                margin-left: 2rem;

                svg {
                    transition: 200ms ease-in-out;
                }
            }

            @media (max-width: 1679px) {
                height: 4.8rem;
                width: 18rem;

                span {
                    height: 3.8rem;
                    width: 3.8rem;
                    padding: 1rem;
                }
            }

            @media (max-width: 1479px) {
                height: 4.4rem;

                span {
                    width: 3.4rem;
                    height: 3.4rem;
                }
            }

            &:hover {
                span {
                    background-color: $baseclr;
                }
            }
        }
    }
}