@import "../../../assets/theme/_var.scss";

.header {
    padding: 3.4rem 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $headerZ;
    width: 100%;
    transition: 200ms ease-in-out;
    border-bottom: 1px solid transparent;

    @media (max-height: 767px) {
        padding: 1.6rem 0;
    }

    &.active {
        padding: 0.8rem 0;
        backdrop-filter: blur(0.4rem);
        background-color: rgba($white, 0.05);
        border-bottom-color: rgba($white, 0.1);
    }


    .container {
        @media (min-width: 1400px) {
            max-width: 162.1rem;
        }
    }

    .header_in {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_logo {
            display: flex;
            align-items: center;
            max-width: 10rem;
        }

        .header_content {
            display: flex;
            align-items: center;

            .header_nav {

                ul {
                    display: flex;
                    align-items: center;
                    margin-inline: -2rem;

                    >li {
                        padding-inline: 2rem;

                        a {
                            cursor: pointer;
                            font-size: 1.4rem;
                            font-weight: 500;
                            position: relative;
                            color: $white;
                            transition: 200ms ease-in-out;

                            &:hover,
                            &.active {
                                color: $baseclr;
                            }
                        }
                    }
                }

                @media (max-width: 991px) {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    max-width: 40rem;
                    height: 100dvh;
                    background-color: #3f4144;
                    z-index: 100;
                    overflow: auto;
                    padding: 3.3rem 2rem;
                    transform: translateX(-100%);
                    transition: 200ms ease-in-out;

                    &.active {
                        transform: translate(0);
                    }

                    ul {
                        display: block;
                        margin: 6rem 0 0;

                        li {
                            padding: 0;

                            &:not(:last-child) {
                                margin-bottom: 3rem;
                            }

                            a {
                                font-size: 2rem;
                                line-height: 2;
                                color: white;
                                width: 100%;

                                &::before {
                                    background-color: white;
                                }
                            }
                        }
                    }
                }

                @media (max-width: 575px) {
                    ul {
                        li {
                            a {
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }

            .header_action {
                margin-left: 2rem;
                display: flex;
                align-items: center;

                >button {
                    &:not(:first-child) {
                        margin-left: 1.6rem;

                        @media (max-width: 479px) {
                            margin-left: 0.8rem;
                        }
                    }
                }

                .action_btn {
                    width: 13rem;
                    height: 4.7rem;
                    font-size: 1.4rem;
                    padding: 0;
                    font-weight: 600;

                    @media (max-width: 991px) {
                        width: 10rem;
                        height: 4.4rem;
                    }

                    @media (max-width: 479px) {
                        height: 3.8rem;
                        font-size: 1.2rem;
                        width: 8rem;
                    }
                }

                .header_toggle {
                    padding: 0;
                    margin: 0;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    z-index: 101;

                    span {
                        width: 3.2rem;
                        display: block;
                        height: 0.3rem;
                        background-color: $baseclr;
                        transform-origin: left;
                        transition: 200ms ease-in-out;

                        &:not(:last-child) {
                            margin-bottom: 0.85rem;
                        }
                    }

                    &.active {
                        span {

                            @media (max-width: 449px) {
                                background-color: white;
                            }

                            &:nth-child(2) {
                                opacity: 0;
                            }

                            &:first-child {
                                transform: rotate(45deg)
                            }

                            &:last-child {
                                transform: rotate(-45deg)
                            }
                        }
                    }
                }
            }
        }
    }
}

.header_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(1rem);
    opacity: 0;
    visibility: hidden;
    transition: 300ms ease-in-out;
    z-index: 99;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}