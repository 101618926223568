@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./assets/theme/_var.scss";

html {
  position: relative;
  font-size: 62.5%;
}

body {
  font-family: $basefont;
  background-color: $body ;
  color: $white;
}

img,
canvas,
iframe,
svg,
video {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

a,
span {
  text-decoration: none;
  display: inline-block;
}

.container {
  padding-inline: 1.5rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.table {
  --bs-table-bg: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black !important;
}



::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $baseclr;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $baseclr2;
}